import React from "react"
import { Layout } from "../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { HomepageNavigation } from "../components/Navigation"
import { PageTile } from "../components/PageTile"
import BackgroundImage from "gatsby-background-image"
import { getImage, ImageDataLike } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

interface HomepageTemplateProps {
  title: string
  namespace: string
  banner: ImageDataLike
  body: string
}

export const HomepageTemplate: React.FC<HomepageTemplateProps> = ({
  title,
  namespace,
  banner,
  body,
}) => {
  const bgImage = convertToBgImage(getImage(banner))
  return (
    <Layout title={title}>
      <HomepageNavigation />
      <BackgroundImage
        tag="div"
        {...bgImage}
        className="relative -mt-10 bg-no-repeat bg-cover -z-10 homepage-banner"
      >
        <div className="container px-4 pt-24 mx-auto">
          <img
            className="mx-auto mb-2 md:max-w-2xl lg:max-w-3xl"
            src={`/media/images/homepage-identity.png`}
            alt="Institute for Studies in Global Prosperity"
          />
          <img
            className="w-3/4 max-w-xs mx-auto md:w-full sm:max-w-md md:max-w-xl"
            src={`/media/images/book-identity.png`}
            alt=""
          />
        </div>
      </BackgroundImage>
      <div className="container flex flex-col flex-grow max-w-5xl px-4 mx-auto my-10 md:flex-row">
        <section className="w-full md:w-1/2 md:mr-16">
          <h1 className="font-semibold leading-snug h2">{title}</h1>
          <MDXRenderer className={namespace}>{body}</MDXRenderer>
        </section>
        <aside className="flex flex-col w-full md:w-1/2 md:mt-5">
          <div className="flex flex-col xs:flex-row">
            <PageTile
              title="History"
              to="/history/"
              image={"/media/images/history.jpg"}
              description="A brief overview of the activities of the Institute for Studies in Global Prosperity since its foundation in 1999."
            />
            <PageTile
              title="Conceptual Framework"
              to="/conceptual-framework/"
              image={"/media/images/conceptual-framework.jpg"}
              description="The questions, concepts and principles that form the basis of the work of the Institute."
            />
          </div>
          <div className="flex flex-col mt-8 xs:flex-row">
            <PageTile
              title="Lines of Action"
              image={"/media/images/lines-of-action.jpg"}
              to="/lines-of-action/"
              description="From educational efforts with university students and young professionals to research initiatives on pressing social issues, the Institute is engaged in a number of lines of action around the world."
            />
            <PageTile
              title="Resources"
              image={"/media/images/resources.jpg"}
              to="/resources/"
              description="A collection of documents that emerge from the Institute’s collaboration with diverse individuals and organizations who share a common commitment to the betterment of society."
            />
          </div>
        </aside>
      </div>
    </Layout>
  )
}

const Homepage = () => {
  const {
    mdx: {
      frontmatter: { title, namespace, banner },
      body,
    },
  } = useStaticQuery(graphql`
    {
      mdx(frontmatter: { template: { eq: "homepage" } }) {
        frontmatter {
          title
          namespace
          banner {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        body
      }
    }
  `)
  return (
    <HomepageTemplate
      title={title}
      namespace={namespace}
      banner={banner}
      body={body}
    />
  )
}

export default Homepage
